/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 620px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.3012048192771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB36oSkmhAC//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMTERL/2gAIAQEAAQUCPZsiUWy15Dsr7//EABYRAQEBAAAAAAAAAAAAAAAAAAABEv/aAAgBAwEBPwGRl//EABcRAAMBAAAAAAAAAAAAAAAAAAABAhL/2gAIAQIBAT8Bps3R/8QAGxABAAICAwAAAAAAAAAAAAAAABExAiEyYaH/2gAIAQEABj8CdKctJj1eT//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQWFxgf/aAAgBAQABPyFQMxougYtlCirOou34VKd9ODCbz5T/2gAMAwEAAgADAAAAEBMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIVh/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhYf/aAAgBAgEBPxBdw0P/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFhgaHR/9oACAEBAAE/ELEgO4du6LXLxFAKU0vk28ta3pZbl2zrK5arfmJWRzmHtn//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"origin of vitamin\"\n        title=\"origin of vitamin\"\n        src=\"/static/1a765c2363367ca8ae3b341de9f754b9/e83c5/vitamin.jpg\"\n        srcset=\"/static/1a765c2363367ca8ae3b341de9f754b9/4d5fb/vitamin.jpg 166w,\n/static/1a765c2363367ca8ae3b341de9f754b9/558f0/vitamin.jpg 333w,\n/static/1a765c2363367ca8ae3b341de9f754b9/e83c5/vitamin.jpg 620w\"\n        sizes=\"(max-width: 620px) 100vw, 620px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "There are certain essential compounds that the body needs in order to survive that subsequently cannot be synthesized by the body. For example, if a sailor is at sea for extended periods of time without the proper nutrition he will come down with scurvy. This is caused by a lack of ascorbic acid (Vitamin C) which is something that the body cannot synthesize on its own. In the early days it was thought that all the essential compounds required to sustain life contained a chemical group known as an “amine”. These “amines” were therefore critical for life or “vita” as its termed in Latin. Hence the name “vital-amine” or “vitamin” as it is used today."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
